"use client";

import { setApiError } from "@/Redux/Slices/errors/apiErrorSlice";
import { useAppDispatch } from "@/Redux/hook";
import { LandingPage } from "@/config/app-constant";
import useGtmPopup from "@/hooks/gtm/useGtmPopup";
import { storageAvailable } from "@/utils/common.utils";
import { useEffect } from "react";

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  useGtmPopup({
    tripType: LandingPage,
    isPopupOpen: !storageAvailable(),
    popupName: "Cookies are blocked",
  });

  if (!storageAvailable()) {
    dispatch(
      setApiError({
        errorStatus: true,
        modalTitle: "Cookies are blocked",
        modalDescription:
          "Unfortunately our journey cannot function with all cookies blocked from Safari settings. To get a quote, please unblock all cookies and manage your cookies via OneTrust on our website.",
        ctaPrimaryBtnLabel: "Refresh",
        httpCode: null,
        errorCode: "456",
        errorMessage: "",
        errors: null,
        multiButton: false,
      })
    );
  }

  return (
    <div>
      <h2>Something went wrong!</h2>
    </div>
  );
}
