import { useEffect } from "react";

export interface ExtendedWindow extends Window {
  dataLayer?: {
    event: string;
    event_name: string;
    step_name: string | undefined;
    popup_name: string | undefined;
  }[];
}

type PopupProps = {
  tripType: string | undefined;
  isPopupOpen: boolean;
  popupName: string | undefined;
};

const useGtmPopup = (props: PopupProps) => {
  const { tripType, isPopupOpen, popupName } = props;
  useEffect(() => {
    const triggerPopup = () => {
      const extendedWindow = window as ExtendedWindow;
      extendedWindow.dataLayer = extendedWindow.dataLayer ?? [];
      extendedWindow.dataLayer.push({
        event: "popup",
        event_name: "qnb_pop_up",
        step_name: tripType,
        popup_name: popupName,
      });
    };
    if (isPopupOpen) triggerPopup();
    return () => {
      // Cleanup code if needed
    };
  }, [tripType, isPopupOpen, popupName]);
};

export default useGtmPopup;
